import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export const constantRoutes = [
  
  {
    path: "",
    component: () => import("@/layout/Layout"),
    redirect: "/",
    hidden: true,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "首页",
          auth: false,
        },
      },
      {
        path: "/operBusiness",
        name: "operBusiness",
        component: () => import("@/views/operBusiness"),
        meta: {
          title: "经营业务",
          auth: false,
        },
      },
      {
        path: "/newsList",
        name: "newsList",
        component: () => import("@/views/news/list"),
        meta: {
          title: "文章列表",
          auth: false,
        },
      },
      {
        path: "newsDetail",
        name: "newsDetail",
        component: () => import("@/views/news/detail"),
        props: true,
        meta: {
          title: "新闻详情",
          auth: false,
        },
      },
      {
        path: "newsIntro",
        name: "newsIntro",
        component: () => import("@/views/news/intro"),
        props: true,
        meta: {
          title: "新闻介绍",
          auth: false,
        },
      },
      {
        path: "/contactUs",
        name: "contactUs",
        component: () => import("@/views/contactUs"),
        props: true,
        meta: {
          title: "联系我们",
          auth: false,
        },
      },
      {
        path: "/employ",
        name: "employ",
        component: () => import("@/views/other/employ"),
        props: true,
        meta: {
          title: "招聘",
          auth: false,
        },
      },
      {
        path: "/partners",
        name: "partners",
        component: () => import("@/views/other/partners"),
        props: true,
        meta: {
          title: "合作伙伴",
          auth: false,
        },
      },
      
    ],
  },
  
];

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: constantRoutes,
  // 路由跳转后 新页面从最顶部显示
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
