import request from '@/utils/request'

// 根据参数键名查询参数值
export function getConfigKey(configKey) {
  return request({
    url: '/front/system/config/configKey/' + configKey,
    method: 'get'
  })
}

// 文件上传相关配置
export function getFileConfig() {
  return request({
    url: '/common/file/config',
    method: 'get'
  })
}